<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.subordinatesProfit") }}</li>
      </ul>
    </div>
    <Loading :loading="loading" />
    <div class="relation_c">
      <p v-if="agencyRelation.length <= 1">{{t('extra.MySubordinates')}}</p>
      <div v-else class="relation_sub">
        <a-button type="primary" @click="changeRelation('myself', 0)"
          >{{t('profile.label.myself')}}</a-button
        >
        <template v-for="(item, index) in agencyRelation" :key="item.userId">
          <span v-show="index != 0"> &nbsp;{{ ">" }}&nbsp; </span>
          <a-button
            v-show="index != 0"
            type="primary"
            @click="changeRelation(item, index)"
            >{{ item.name }}
          </a-button>
        </template>
      </div>
    </div>
    <div class="options_c">
      <ul class="flexUl">
        <a-date-picker
          class="datePicker"
          v-model:value="startTime"
          :placeholder="t('common.date.SELECT_START_DATE')"
        />
        <a-date-picker
          class="datePicker"
          v-model:value="endTime"
          :placeholder="t('common.date.SELECT_END_DATE')"
        />
      </ul>
      <a-input
        class="searchInput"
        v-model:value="searchName"
        :placeholder="t('common.placeholder.searchPhone1')"
      ></a-input>
      <a-button
        type="primary"
        class="searchButton"
        @click="listSubordinates(true, true)"
        >{{t('common.button.search')}}</a-button
      >
    </div>
    <a-drawer
      class="subordinates_drawer"
      :placement="'top'"
      :visible="drawer_visible"
      @close="drawer_visible = false"
    >
      <div class="operation_c">
        <p>{{ "operation of " + userAccount }}</p>
        <a-button type="primary" @click="goReports">{{t('extra.goReports')}}</a-button>
        <a-button
          type="primary"
          v-if="+record_select?.reportNumber > 0"
          @click="listSubordinates()"
          >{{t('extra.listTheSubordinates')}}</a-button
        >
      </div>
    </a-drawer>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll_3"
    >
      <div class="main main_page">
        <div class="Account_list">
          <div class="no_data" v-if="!listItems || listItems.length == 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <div
            class="bata subordinatesManagement_data"
            v-else
            @touchmove="onTmove"
            @touchstart="onTstart"
          >
            <a-table
              :scroll="{ x: true }"
              :columns="columns"
              :data-source="listItems"
              :pagination="false"
              class="ant-table-striped"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :customRow="
                (record) => {
                  return {
                    onClick: () => {
                      handleClick(record);
                    },
                  };
                }
              "
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'userAccount'">
                  <span :style="{ color: '#1890FF' }">{{
                    record.userAccount
                  }}</span>
                </template>
                <template v-if="column.key === 'profitTotal'">
                  <span :style="{ color: 'red' }">{{
                    formatMoney(record.profitTotal, false)
                  }}</span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import api from "@/api";
import { useI18n } from "@/lang";
import { agency } from "@/hooks/agency";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, watch } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import { formatDate, formatMoney } from "@/utils/formatter";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { userDetail } from "@/hooks/userDetail";
import Loading from "@/components/Loading.vue";
import { moveXY, onTmove, onTstart } from "@/utils/moveXY";
export default {
  components: {
    BetterScroll,
    Loading,
  },
  setup() {
    watch(moveXY, () => {
      let ele = document.querySelector(".ant-table-content");
      let x =
        document.querySelector(".ant-table-content").scrollLeft - moveXY.X;
      ele.scrollTo(x, 0);
    });
    const { t } = useI18n();
    const loading = ref(false);
    const router = useRouter();
    const startTime = ref(dayjs(new Date()));
    const endTime = ref(dayjs(new Date()));
    const drawer_visible = ref(false);
    const alreadyReq = ref(false);
    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const currentPage = ref(0);
    const agencyRelation = reactive([
      {
        userId: "myself",
        name: t("profile.label.myself"),
      },
    ]);
    watch(agencyRelation, () => {
      let ele = document.querySelector(".relation_c");
      setTimeout(()=>{ele.scrollTo(ele.scrollWidth,0)},100)
    });
    const searchName = ref("");
    const record_select = ref(null);
    const userId = ref("myself");
    const userAccount = ref("");
    const current_userAccount = ref("");
    const current_id = ref("myself");
    const columns = ref([
      {
        title: t("profile.label.accountName"),
        dataIndex: "userAccount",
        key: "userAccount",
        width: "25%",
      },
      {
        title: t("profile.label.agencyType"),
        dataIndex: "memberType",
        key: "memberType",
        width: "25%",
      },
      {
        title: t("profile.label.subordinateNumber1"),
        dataIndex: "reportNumber",
        key: "reportNumber",
      },
      {
        title: t("profile.label.profitTotal"),
        dataIndex: "profitTotal",
        key: "profitTotal",
        width: "50%",
      },
    ]);
    const listItems = reactive([]);
    const changeRelation = (item, index) => {
      userId.value = item.userId;
      userAccount.value = item.name;
      agencyRelation.splice(index + 1, agencyRelation.length);
      listSubordinates();
    };
    const listSubordinates = (isKeepCurrentId, isByName) => {
      alreadyReq.value = false;
      isStopReq.value = false;
      currentPage.value = 0;
      listItems.splice(0, listItems.length);
      if (!isKeepCurrentId) {
        current_id.value = userId.value;
        current_userAccount.value = userAccount.value;
      }
      if (isByName && searchName.value) {
        agencyRelation.splice(1, agencyRelation.length);
        fetchMorePage(true);
      } else {
        fetchMorePage();
      }
    };
    const goReports = () => {
      agency.value = {
        userAccount: userAccount.value,
        current: "teamReports",
        teamAccountRecordsType: "transactionRecord",
      };
      router.push("/agencyRecords");
    };
    const handleClick = (record) => {
      if (record.userAccount == userDetail?.value?.userAccount) {
        return;
      }
      record_select.value = record;
      userId.value = record.userId;
      userAccount.value = record.userAccount;
      drawer_visible.value = true;
    };
    const fetchMorePage = (isByName) => {
      if (isStopReq.value) {
        return;
      }
      let formToSend = {
        current: currentPage.value + 1,
        size: 10,
      };
      formToSend.startTime = moment(
        new Date(dayjs(startTime.value.$d).format("YYYY/MM/DD") + " 00:00:00")
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.endTime = moment(
        new Date(dayjs(endTime.value.$d).format("YYYY/MM/DD") + " 23:59:59")
      ).format("YYYY-MM-DD HH:mm:ss");
      if (isByName) {
        formToSend.userAccount = searchName.value;
      } else {
        if (current_id.value && current_id.value != "myself") {
          formToSend.userId = current_id.value;
          // if(current_userAccount.value){
          //   formToSend.userAccount = current_userAccount.value;
          // }
        }
      }
      loading.value = true;
      api
        .agencySubReport(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            drawer_visible.value = false;
            if ((userId.value && userId.value != "myself") || isByName) {
              if (isByName && res.data?.data?.records?.[0]) {
                if (
                  res.data?.data?.records?.[0]?.userId == userDetail?.value?.id
                ) {
                  current_id.value = "self";
                } else {
                  current_id.value = res.data?.data?.records?.[0]?.userId;
                }
                if (
                  agencyRelation?.[agencyRelation.length - 1]?.userId !=
                    res.data?.data?.records?.[0]?.userId &&
                  res.data?.data?.records?.[0]?.userId != userDetail?.value?.id
                ) {
                  agencyRelation.push({
                    userId: res.data?.data?.records?.[0]?.userId,
                    name: searchName.value,
                  });
                }
              } else {
                if (
                  agencyRelation?.[agencyRelation.length - 1]?.userId !=
                    userId.value &&
                  res.data?.data?.records?.[0]?.userId != userDetail?.value?.id
                ) {
                  agencyRelation.push({
                    userId: userId.value,
                    name: userAccount.value,
                  });
                }
              }
            }
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          } else {
            current_id.value =
              agencyRelation?.[agencyRelation.length - 1]?.userId;
          }
          searchName.value = "";
        })
        .catch(() => {
          loading.value = false;
          searchName.value = "";
          current_id.value =
            agencyRelation?.[agencyRelation.length - 1]?.userId;
        });
    };
    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });
    onMounted(() => {
      fetchMorePage();
    });
    return {
      t,
      loading,
      columns,
      listItems,
      handleClick,
      fetchMorePage,
      alreadyReq,
      agencyRelation,
      scrollRef,
      formatDate,
      formatMoney,
      drawer_visible,
      userAccount,
      listSubordinates,
      goReports,
      changeRelation,
      record_select,
      searchName,
      startTime,
      endTime,
      onTmove,
      onTstart,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
.relation_c {
  box-sizing: border-box;
  padding: 0px 8px;
  position: fixed;
  top: 110px;
  height: 50px;
  width: 100vw;
  z-index: 100;
  overflow-x: scroll;
  background: #f5f4f7;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  p {
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #666;
  }
  .relation_sub {
    padding-top: 10px;
    span {
      font-size: 18px;
      font-weight: bold;
      color: #666;
    }
  }
}
.options_c {
  top: 45px;
  position: fixed;
  height: 65px;
  width: 100%;
  z-index: 100;
  background: #f5f4f7;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-wrap: wrap;
  .flexUl {
    display: flex;
    box-sizing: border-box;
    width: 100%;
  }
  .searchButton {
    width: 75px;
  }
  .searchInput {
    width: 300px;
    background: #fff;
  }
}
.operation_c {
  text-align: center;
  p {
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
  button {
    width: 200px;
    height: 40px;
    margin-bottom: 15px;
  }
}
</style>
<style lang="scss">
.subordinates_drawer {
  .ant-drawer-content-wrapper {
    height: 270px !important;
  }
}
.options_c {
  .flexUl {
    .ant-picker {
      width: 50% !important;
    }
  }
}
</style>
<style scoped>
.subordinatesManagement_data .ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.subordinatesManagement_data :deep(.ant-table) {
  max-height: none !important;
}
</style>