import {
    reactive
} from 'vue'
export const moveXY = reactive({
    X: 0,
    Y: 0
})
let startX
let startY
let moveEndX
let moveEndY
export const onTstart = (e) => {
    startX = e.changedTouches[0].pageX
    startY = e.changedTouches[0].pageY
}
export const onTmove = (e) => {
    moveEndX = e.changedTouches[0].pageX
    moveEndY = e.changedTouches[0].pageY
    moveXY.X= moveEndX - startX
    moveXY.Y= moveEndY - startY
    startX = e.changedTouches[0].pageX
    startY = e.changedTouches[0].pageY
}